import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
    Row,
    Col,
    Spinner,
    Modal,
    ModalBody,
    Label,
    Input,
    Button,
    Form,
    ModalHeader,
} from "reactstrap"
import {withRouter} from "react-router-dom"
import DataTable from "react-data-table-component"
import {withTranslation} from "react-i18next"
import {Link} from "react-router-dom"
import Switch from "react-switch"

import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit"
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"

const DataTableEtudiant = props => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [modal, setModal] = useState(false)
    const [id, setId] = useState("")
    const [selectAction, setSelectAction] = useState("")
    const {SearchBar} = Search
    const [actions, setActions] = useState([
        {value: 1, label: "Génération du compte"},
        {value: 2, label: "Suppression"},
    ])
    const [arraySelect, setArraySelect] = useState([])
    const [arraySelectAll, setArraySelectAll] = useState([])
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    const [view, setView] = useState(0);
    const [delet, setDelet] = useState(0);
    const [add, setAdd] = useState(0);
    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: data.length,
        page: 1,
        nextPageText: "Next",
        prePageText: "Back",
        sizePerPage: 30,
        alwaysShowAllBtns: true,
        sizePerPageList: [
            {
                text: "5",
                value: 5,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "20",
                value: 20,
            },
            {
                text: "30",
                value: 30,
            },
            {
                text: "All",
                value: data.length,
            },
        ],
    }
    const [modal1, setModal1] = useState(false)
    const [etudiantId, setEtudiantId] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [cloture, setCloture] = useState(0);

    const NoDataIndication = () => (
        <h6 style={{textAlign: "center"}}>No record to display</h6>
    )
    const Columns = () => [
        {
            dataField: "cin",
            text: "CIN",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "nom",
            text: "Nom et prénom",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "group",
            text: "Groupe",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "mobile",
            text: "Téléphone",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "email",
            text: "E-mail",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "Consultation",
            text: "Consultation",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                view == 1 ? (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Link to={"/DetailEtudiant?id=" + row.id}>
                            <i
                                style={{color: "mediumslateblue", cursor: "pointer"}}
                                className="fas fa-eye"
                            ></i>
                        </Link>
                    </div>
                ) : (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <i
                            style={{color: "#a7a3a3", cursor: "pointer"}}
                            className="fas fa-eye"
                        ></i>
                    </div>
                )
            ),
            center: true,
            reorder: true,
        },
        {
            dataField: "Suppression",
            text: "Suppression",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                delet === 1 ? (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <i
                            style={{color: "#ED6464", cursor: "pointer"}}
                            onClick={() => toggle(row.id)}
                            className="fas fa-trash-alt"
                        ></i>
                    </div>
                ) : (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <i
                            style={{color: "#a7a3a3", cursor: "pointer"}}
                            className="fas fa-trash-alt"
                        ></i>
                    </div>
                )
            ),
            center: true,
            reorder: true,
        },
        {
            dataField: "Compte",
            isDummyField: true,
            text: "Compte",
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) =>
                cloture != 1 ? (
                    delet === 1 ? (
                        row.is_compte == -1 ? (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <i
                                    style={{color: "blueviolet", cursor: "pointer"}}
                                    className="fas fa-user-plus"
                                    onClick={() => addAccount(row)}
                                ></i>
                            </div>
                        ) : row.is_compte == 1 && row.active_compte == 0 ? (
                            <div
                                onClick={() => toggle1(row)}
                                style={{display: "flex", justifyContent: "center"}}
                            >
                                <i
                                    style={{color: "red", cursor: "pointer"}}
                                    className="fas fa-user"
                                ></i>
                            </div>
                        ) : (
                            <div
                                onClick={() => toggle1(row)}
                                style={{display: "flex", justifyContent: "center"}}
                            >
                                <i
                                    style={{color: "#34c38f", cursor: "pointer"}}
                                    className="fas fa-user"
                                ></i>
                            </div>
                        )
                    ) : (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <i
                                style={{color: "#A7A3A3", cursor: "pointer"}}
                                className="fas fa-user"
                                title="Vous n'avez pas le droit de fiche de voeux"
                            ></i>
                        </div>
                    )
                ) : (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <i
                            style={{color: "#A7A3A3", cursor: "pointer"}}
                            className="fas fa-user"
                            title="Année clôturée"
                        ></i>
                    </div>
                )
        },
        {
            dataField: "active",
            isDummyField: true,
            text: "Etat",
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (colIndex, row) => (
                cloture != 1 ? (
                    add === 1 ? (
                        <Switch
                            uncheckedIcon={<Offsymbol/>}
                            checkedIcon={<OnSymbol/>}
                            className="me-1 mb-sm-8 mb-2"
                            onColor="#34c38f"
                            onChange={() => activeEtudiant(row)}
                            checked={row.active}
                        />
                    ) : (
                        <Switch
                            uncheckedIcon={<Offsymbol/>}
                            checkedIcon={<OnSymbol/>}
                            className="me-1 mb-sm-8 mb-2"
                            onColor="#34c38f"
                            onChange={() => activeEtudiant(row)}
                            checked={row.active}
                            disabled={true}
                            readOnly={true}
                        />
                    )
                ) : (
                    <Switch
                        uncheckedIcon={<Offsymbol/>}
                        checkedIcon={<OnSymbol/>}
                        className="me-1 mb-sm-8 mb-2"
                        onColor="#34c38f"
                        // onChange={() => activeEnseignant(row)}
                        checked={row.active}
                        disabled={true}
                        readOnly={true}
                    />
                )
            ),
        },
    ]
    const activeEtudiant = async row => {
        var active = row.active
        var etudiantId = row.id
        if (active == 0) {
            var active = 1
            const res = await API.post("UserEtudiant/activate", {
                id: etudiantId,
                active: active,
            }).then(res => {
                setModal1(false)
                toast.success(" 🔓✔️ Compte Activé Successfully", {
                    containerId: "A",
                })
                API.post("etudiant/list_etudiant", {annee_id}).then(resE => {
                    setData(resE.data.etudiants)
                    setLoading(true)
                })
            })
        } else if (active == 1) {
            var active = 0
            const res = await API.post("UserEtudiant/activate", {
                id: etudiantId,
                active: active,
            }).then(res => {
                setModal1(false)
                toast.success(" 🔓✔️ Compte Activé Successfully", {
                    containerId: "A",
                })
                API.post("etudiant/list_etudiant", {annee_id}).then(resE => {
                    setData(resE.data.etudiants)
                    setLoading(true)
                })
            })
        }
    }
    const Offsymbol = () => {
        return (
            <div
                title="Activé"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                A
            </div>
        )
    }

    const OnSymbol = props => {
        return (
            <div
                title="Désactivé"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                D
            </div>
        )
    }
    const toggle1 = async row => {
        setModal1(!modal1)
        setName(row.nom)
        setEmail(row.email)
        setEtudiantId(row)
    }
    const addAccount = async row => {
        var etudiant = row
        const res = await API.post("UserEtudiant/add", {
            etudiant: etudiant, annee_id
        }).then(res => {
            if (res.data.errNum == 422) {
                toast.error("Compte est déjà utilisé !! ", {
                    containerId: "A",
                })
            } else {
                toast.success("Compte Ajouté Successfully ", {
                    containerId: "A",
                })
                API.post("etudiant/list_etudiant", {annee_id}).then(resE => {
                    setData(resE.data.etudiants)
                    setLoading(true)
                })
            }

        })
    }
    const toggle2 = async row => {
        setModal1(!modal1)
    }
    const toggle = id => {
        setModal(!modal)
        setId(id)
    }
    const toggleDelete = async () => {
        const res = await API.post("etudiant/delete", {
            id: id,
            annee_id
        }).then(res => {
            if (res.data.errNum == 301) {
                toast.error("Cet étudiant est déjà utilisé !", {
                    containerId: "A",
                })
            } else {
                setModal(false)
                API.post("etudiant/list_etudiant", {annee_id}).then(resE => {
                    setData(resE.data.etudiants)
                    setLoading(true)
                })
            }
        })
    }

    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Etudiants")
        );
        setView(typeArticleSubMenu.sous_menu[0].view)
        setDelet(typeArticleSubMenu.sous_menu[0].delete)
        setAdd(typeArticleSubMenu.sous_menu[0].add)
        const resE = await API.post("etudiant/list_etudiant", {annee_id}).then(resE => {
            setData(resE.data.etudiants)
            setLoading(true)
        })
    }, [])
    const action = async () => {

        if (selectAction != "") {
            if (selectAction.value == 1) {
                if (arraySelectAll.length != 0) {
                    var etudiants = arraySelectAll
                } else {
                    var etudiants = arraySelect
                }

                const resEtudaint = await API.post("UserEtudiant/activer_generation_compte_etudiant", {
                    etudiants: etudiants,
                    annee_id
                }).then(res => {
                    setSelectAction("")
                    setArraySelectAll([])
                    API.post("etudiant/list_etudiant", {annee_id}).then(resE => {
                        setData(resE.data.etudiants)
                        setLoading(true)
                        setArraySelect([]);
                    })

                    toast.success(" Les Comptes Générés Avec succès ", {
                        containerId: "A",
                    })

                })
            } else if (selectAction.value == 2) {
                if (arraySelectAll.length != 0) {
                    var etudiants = arraySelectAll
                } else {
                    var etudiants = arraySelect
                }
                const resD = await API.post("etudiant/deletes", {
                    etudiants: etudiants,
                    annee_id
                }).then(resD => {
                    setArraySelectAll([])
                    if (resD.data.errNum == 301) {
                        toast.error("Cet étudiant est déjà utilisé !", {
                            containerId: "A",
                        })
                    } else {
                        setSelectAction("")
                        API.post("etudiant/list_etudiant", {annee_id}).then(resE => {
                            setData(resE.data.etudiants)
                            setLoading(true)
                            setArraySelect([]);
                        })
                    }
                }).catch(error => {
                    console.error("Erreur lors de l'appel API", error);
                });
            }
        } else {
            toast.error("Voulez-vous choisir une action !", {
                containerId: "A",
            })
        }

    }
    // const selectRow = {
    //     mode: "checkbox",
    //     onSelect: (row, isSelect, rowIndex, e) => {
    //         if (isSelect) {
    //             var array = [...arraySelect]; // Utilisez une copie de l'état pour éviter les mutations
    //             array.push(row);
    //             setArraySelect(array);
    //
    //             let tab = [...arraySelectAll];
    //             tab.push(row);
    //             setArraySelectAll(tab);
    //         } else {
    //             var array = [...arraySelect];
    //             array.splice(rowIndex, 1);
    //             setArraySelect(array);
    //
    //             if (arraySelectAll.length !== 0) {
    //                 let tab = [...arraySelectAll];
    //                 let etudId = row.id;
    //                 for (let index = 0; index < tab.length; index++) {
    //                     let idEtud = tab[index].id;
    //                     if (idEtud === etudId) {
    //                         tab.splice(index, 1);
    //                         setArraySelectAll(tab);
    //                         break;
    //                     }
    //                 }
    //             }
    //         }
    //     },
    //     onSelectAll: (isSelect, rows, e) => {
    //         if (isSelect) {
    //             var array = [...arraySelectAll]; // Créez une nouvelle référence
    //             for (let i = 0; i < rows.length; i++) { // Utilisez 'rows' pour ajouter toutes les lignes sélectionnées
    //                 array.push(rows[i]);
    //             }
    //             setArraySelectAll(array);
    //         } else {
    //             setArraySelectAll([]);
    //             setArraySelect([]); // Réinitialisez aussi arraySelect lorsque tout est décoché
    //         }
    //     },
    // };



    const selectRow = {
        mode: "checkbox",
        selected: arraySelect.map(item => item.id), // Suivre les IDs sélectionnés
        onSelect: (row, isSelect) => {
            if (isSelect) {
                setArraySelect(prev => [...prev, row]);
            } else {
                setArraySelect(prev => prev.filter(item => item.id !== row.id));
            }
        },
        onSelectAll: (isSelect, rows) => {
            if (isSelect) {
                setArraySelect(rows); // Sélectionner toutes les lignes
            } else {
                setArraySelect([]); // Désélectionner toutes les lignes
            }
        },
    };
    const activate = async etudiantId => {
        var id = etudiantId.id
        var active = etudiantId.active_compte
        if (active == 0) {
            var active = 1
            const res = await API.post("UserEtudiant/activate", {
                id: id,
                active: active,
            }).then(res => {
                setModal1(false)
                toast.success(" 🔓✔️ Compte Activé Successfully", {
                    containerId: "A",
                })
                API.post("etudiant/list_etudiant", {annee_id}).then(resE => {
                    setData(resE.data.etudiants)
                    setLoading(true)
                })
            })
        } else if (active == 1) {
            var active = 0
            const res = await API.post("UserEtudiant/activate", {
                id: id,
                active: active,
            }).then(res => {
                setModal1(false)
                toast.success(" 🔒❌ Compte Désactivé Successfully", {
                    containerId: "A",
                })
                API.post("etudiant/list_etudiant", {annee_id}).then(resE => {
                    setData(resE.data.etudiants)
                    setLoading(true)
                })
            })
        }
    }
    const edit = async etudiantId => {
        var id = etudiantId.id
        if (password != "") {
            const res = await API.post("UserEtudiant/update", {
                id: id,
                email: email,
                password: password,
            }).then(res => {
                setModal1(false)
                toast.success(" 🔓✔ Compte étudiant est modifié avec Successfully", {
                    containerId: "A",
                })
                API.post("etudiant/list_etudiant", {annee_id}).then(resE => {
                    setData(resE.data.etudiants)
                    setLoading(true)
                })
            })
        } else {
            setModal1(true)
            toast.error("⛔ Mot de passe est obligatoires", {
                containerId: "A",
            })
        }
    }
    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <Row className="mt-4">
                        <Col xs="12">
                            <ToolkitProvider
                                keyField="id"
                                data={data}
                                columns={Columns()}
                                search
                                bootstrap4
                            >
                                {toolkitProps => (
                                    <div>
                                        <div>
                                            <div className="mb-3 row" style={{display: "flex"}}>
                                                <div className="col-md-6 mb-3 row">
                                                    <div className="col-md-8 select-action">
                                                        <Select
                                                            value={selectAction}
                                                            options={actions}
                                                            isSearchable={true}
                                                            onChange={e => setSelectAction(e)}
                                                            placeholder="Actions"
                                                        />
                                                    </div>
                                                    <div className="col-md-4 appliquer-button">
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            className="btn btn-primary"
                                                            onClick={action}
                                                        >
                                                            Appliquer
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3 row">
                                                    <div className="col-md-8 search-box-etd">
                                                        <div
                                                            className="search-box me-2 mb-2 d-inline-block"
                                                            style={{width: "100%"}}
                                                        >
                                                            <div className="position-relative">
                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                <i className="bx bx-search-alt search-icon"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            noDataIndication={() => <NoDataIndication />}
                                            striped={false}
                                            bordered={false}
                                            selectRow={selectRow}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"table-light"}
                                            hover
                                            pagination={paginationFactory(pageOptions)}
                                            {...toolkitProps.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary"/>
                        </div>
                        <h4
                            style={{textAlign: "center", marginTop: "2%"}}
                            className="ms-6"
                        >
                            {" "}
                            {props.t("Chargement en cours...")}
                        </h4>
                    </div>
                )}
            </div>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader
                    style={{width: "100% !important", textAlign: "center !important"}}
                    toggle={toggle}
                    tag="h4"
                >
                    {" Suppression etudiant"}{" "}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <p>Voulez-vous supprimer cet etudiant ?</p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    color="info"
                                    className="btn-rounded "
                                    onClick={toggleDelete}
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    Oui
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggle}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Non
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modal1} toggle={toggle2} centered={true}>
                <ModalHeader
                    style={{width: "100% !important", textAlign: "center !important"}}
                    toggle={toggle2}
                    tag="h4"
                >
                    {name}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Form>
                            <Row>
                                <Col lg="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">Email</Label>
                                        <Input
                                            lg="3"
                                            type="email"
                                            className="form-control"
                                            placeholder="Email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">Password</Label>
                                        <Input
                                            lg="3"
                                            type="password"
                                            className="form-control"
                                            placeholder="Password"
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <div lg="4">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                    className="text-center mt-4"
                                >
                                    {etudiantId.active_compte == 0 ? (
                                        <Button
                                            type="button"
                                            color="success"
                                            className="btn btn-primary  mb-2 me-2"
                                            onClick={() => activate(etudiantId)}
                                        >
                                            Activer
                                        </Button>
                                    ) : (
                                        <Button
                                            type="button"
                                            color="danger"
                                            className="btn btn-primary  mb-2 me-2"
                                            onClick={() => activate(etudiantId)}
                                        >
                                            Desactiver
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <di lg="4">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                    }}
                                    className="text-center mt-4"
                                >
                                    <Button
                                        type="button"
                                        color="warning"
                                        className="btn btn-warning  mb-2 me-2"
                                        onClick={toggle2}
                                    >
                                        Annuler
                                    </Button>
                                </div>
                            </di>
                            <div lg="4">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                    }}
                                    className="text-center mt-4"
                                >
                                    <button
                                        type="button"
                                        className="btn btn-primary "
                                        onClick={() => edit(etudiantId)}
                                    >
                                        Confirmer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Row>
                </ModalBody>
            </Modal>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(DataTableEtudiant))
DataTableEtudiant.propTypes = {
    data: PropTypes.array,
    history: PropTypes.object,
    t: PropTypes.any,
}
